import React, { useEffect, useState } from "react";
import TvPlayerComponent from "../src/components/tvPlayer/tvPlayer";
import channelData from "../src/constants/channelData";
import { useLocation, Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { PiScreencastLight } from "react-icons/pi";
import { useCast } from "@jdion/cast-react";
import { TbCastOff } from "react-icons/tb";

const TvPlayer = () => {
  const getChannelData = channelData;
  const [data, setData] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(true);
  const id = query.get("id");
  const { player } = useCast();

  useEffect(() => {
    setTimeout(() => {
      setData([...getChannelData]);
      setLoading(false); // Cambia el estado de carga a falso una vez que los datos están disponibles
    }, 1000);
  }, []);

  const startCast = async (url, title) => {
    await player.startCast(url);
  };

  const stopCast = async (url) => {
    await player.endCast(url);
  };

  if (loading) {
    // Muestra un spinner mientras se carga
    return (
      <div className="flex items-center justify-center h-screen">
        <div
          className="spinner-border animate-spin inline-block w-10 h-10 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden"></span>
        </div>
      </div>
    );
  }
  return (
    <>
      {data.map((item, index) => {
        if (item.id == id)
          return (
            <div key={index} className="relative w-full  xl:my-0.5 group">
              <div className="absolute bg-black top-0 left-0 right-0 z-10 flex justify-between items-center p-2 opacity-0 group-hover:opacity-70 transition-opacity duration-300 pointer-events-none group-hover:pointer-events-auto">
                <Link
                  to="/"
                  className="cursor-pointer rounded-full border border-white p-4 pointer-events-auto"
                >
                  <FaArrowLeft className="text-3xl" />
                </Link>
                {!item.url.includes("youtube") && (
                  <span className="flex gap-3">
                    <button
                      onClick={() => stopCast(item.url)}
                      className="cursor-pointer rounded-full border border-white p-4 pointer-events-auto"
                    >
                      <TbCastOff className="text-3xl text-white" />
                    </button>
                    <button
                      onClick={() => startCast(item.url, item.title)}
                      className="cursor-pointer rounded-full border bg-white text-black p-4 pointer-events-auto"
                    >
                      <PiScreencastLight className="text-3xl" />
                    </button>
                  </span>
                )}
              </div>
              <div>
                {!item.url.includes("ia902704.us.archive.org") ? (
                  <TvPlayerComponent
                    url={item.url}
                    fallbackUrl={item.url2}
                    isAudio={item.title === "Cadena 3"}
                    img={item.img}
                  />
                ) : (
                  <video controls width={"100%"}>
                    <source src={item.url} type="video/mp4" />
                    Tu navegador no soporta la reproducción de video.
                  </video>
                )}
              </div>
            </div>
          );
      })}
    </>
  );
};

export default TvPlayer;
