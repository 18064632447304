import React from "react";

const RadioCard = ({ img, title, onSelect }) => {
  return (
    <div
      className="h-full w-full flex gap-3 border rounded-md items-cente"
      onClick={onSelect}
    >
      <img
        src={img}
        className="w-full md:w-36 h-36 rounded-md bg-white opacity-90 "
        alt="imagen_radio"
      />
      <p className="text-2xl text-center px-2 hidden md:block">{title}</p>
    </div>
  );
};

export default RadioCard;
