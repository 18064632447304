import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const RadioPlayer = ({ src, title, img }) => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center gap-3 p-4">
      <img
        src={img}
        className="w-24 h-24 md:w-36 md:h-36 rounded-md"
        alt="imagen_radio"
      />
      <div className="flex flex-col gap-3 items-center md:items-start text-center md:text-left">
        <p className="text-lg md:text-2xl">{title}</p>
        <AudioPlayer
          loop={false}
          customAdditionalControls={[]}
          style={{
            width: "130%",
            maxWidth: "500px",
            borderRadius: "10px",
            backgroundColor: "#f1f5f9",
          }}
          src={src}
        />
      </div>
    </div>
  );
};

export default RadioPlayer;
