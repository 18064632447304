import React from "react";

const GameCard = ({ img, url, id, title, description }) => {
  return (
    <div
      className="h-[250px] md:h-[394px] w-full  my-3 z-0 border border-white rounded-md"
      id={id}
    >
      <a className="h-[150px] md:h-[230px] w-full z-0" href={url}>
        <img
          className="bg-white opacity-90 rounded-md w-full relative h-[150px] md:h-[280px] border border-white shadow-sm shadow-white"
          src={img}
          fill
          alt={title}
        />
        <h2 className="text-center my-2 font-bold text-2xl">{title}</h2>
        <p className="mx-5 my-3 text-base text-justify">{description}</p>
        <p className="p-2 bg-white text-slate-700 w-full text-center mx-auto rounded-sm font-semibold">
          Jugar
        </p>
      </a>
    </div>
  );
};

export default GameCard;
