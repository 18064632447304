import React, { useState } from "react";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BiJoystick } from "react-icons/bi";
import { Link } from "react-router-dom";
import { MdLiveTv } from "react-icons/md";
import { MdOutlineRadio } from "react-icons/md";
import { IoMdMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";

const Navbar = ({ value, onChange, handleKeyPress }) => {
  const [open, setOpen] = useState(false);
  return (
    <nav className="bg-gray-800 p-1 lg:p-4 border-b border-slate-700 top-0 fixed z-50 w-full ">
      <div className="mx-1  md:mx-3 flex  justify-between items-center">
        <div className="text-white text-xl font-bold">
          <Link to="/" className="flex gap-2 items-center">
            <img
              src="/isologo.png"
              className="object-contain w-[150px] md:w-[200px] lg:w-[280px] "
              alt="NETMAX Logo"
            />
          </Link>
        </div>
        <span className="hidden lg:flex w-full">
          <input
            type="search"
            placeholder="Buscar..."
            value={value}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            className="w-3/4 px-4 py-2 mx-auto rounded-full bg-slate-700 items-center"
          />
        </span>

        <div className="hidden md:flex space-x-4 mr-2 items-center">
          <Link
            to="/"
            className="text-gray-300 flex items-center text-3xl gap-2 -mt-1 hover:text-white"
          >
            <MdLiveTv />
          </Link>
          <Link
            to="/radio"
            className="text-gray-300 flex items-center text-3xl gap-2 -mt-1 hover:text-white"
          >
            <MdOutlineRadio />
          </Link>
          <Link
            to="/game"
            className="text-gray-300 flex items-center text-3xl gap-2 hover:text-white"
          >
            <BiJoystick />
          </Link>

          <Link
            to="https://www.netmax.com.ar/contacto.html"
            className="text-gray-300 flex items-center text-3xl gap-2 hover:text-white"
          >
            <MdOutlineSupportAgent className="" />
          </Link>
        </div>
        <div className="flex md:hidden">
          {!open ? (
            <button
              className="text-gray-300 flex items-center text-3xl gap-2 hover:text-white"
              onClick={() => setOpen(true)}
              type="button"
            >
              <IoMdMenu />
            </button>
          ) : (
            <button
              className="text-white flex items-center text-3xl gap-2 hover:text-white absolute top-2 right-3"
              onClick={() => setOpen(false)}
              type="button"
            >
              <IoMdClose />
            </button>
          )}{" "}
          {open && (
            <div className="md:hidden flex flex-col p-2 w-1/2 bg-gray-800 absolute top-14 right-0 z-30">
              <Link
                to="/"
                className="block text-gray-300 text-xl py-2 hover:text-white"
                onClick={() => setOpen(false)}
              >
                <MdLiveTv className="inline-block mr-2" /> TV
              </Link>
              <Link
                to="/radio"
                className="block text-gray-300 text-xl py-2 hover:text-white"
                onClick={() => setOpen(false)}
              >
                <MdOutlineRadio className="inline-block mr-2" /> Radio
              </Link>
              <Link
                to="/game"
                className="block text-gray-300 text-xl py-2 hover:text-white"
                onClick={() => setOpen(false)}
              >
                <BiJoystick className="inline-block mr-2" /> Juegos
              </Link>
              <Link
                to="https://www.netmax.com.ar/contacto.html"
                className="block text-gray-300 text-xl py-2 hover:text-white"
                onClick={() => setOpen(false)}
              >
                <MdOutlineSupportAgent className="inline-block mr-2" /> Contacto
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="flex lg:hidden my-2">
        <input
          type="search"
          placeholder="Buscar..."
          value={value}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          className="w-full px-4 py-2 mx-auto rounded-full bg-slate-700 items-center"
        />
      </div>
    </nav>
  );
};

export default Navbar;
