import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";

const TvPlayerComponent = ({ url, fallbackUrl, isAudio, img }) => {
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null);
  const [currentUrl, setCurrentUrl] = useState(() => {
    const savedUrl = localStorage.getItem("videoUrl");
    return savedUrl || url;
  });

  useEffect(() => {
    if (fallbackUrl) {
      const timer = setTimeout(() => {
        if (loading) {
          setCurrentUrl(fallbackUrl);
        }
      }, 1000); // 1 segundo

      return () => clearTimeout(timer);
    }
  }, [loading, fallbackUrl]);

  const handleReady = () => {
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center w-full">
      {isAudio ? (
        <div className="flex flex-col items-center w-full">
          <img
            src={img}
            alt=""
            className="w-full object-cover"
            style={{ height: "650px" }}
          />
          <audio controls autoPlay>
            <source src={currentUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      ) : (
        <ReactPlayer
          ref={videoRef}
          url={currentUrl}
          playing
          width={"100%"}
          controls={true}
          height={700}
          onReady={handleReady}
          config={{
            file: {
              attributes: {
                crossOrigin: "anonymous",
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default TvPlayerComponent;
