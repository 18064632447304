import React, { useEffect, useState } from "react";
import Navbar from "./components/menu/navbar";
import Footer from "./components/menu/footer";
import radioData from "./constants/radioData";
import RadioPlayer from "./components/radio/radioPlayer";
import RadioCard from "./components/radio/radioCard";

const Radio = () => {
  const [filterData, setFilterData] = useState("");
  const [filteredRadio, setFilteredRadio] = useState(radioData);
  const [selectedRadio, setSelectedRadio] = useState({
    url: "https://27573.live.streamtheworld.com/LAPOPU.mp3?dist=c3-web",
    img: "/tv/lapopufm.png",
    title: "La Popu FM",
  });

  useEffect(() => {
    const filtered = radioData.filter((item) =>
      item?.title?.toLowerCase()?.includes(filterData?.toLowerCase())
    );
    setFilteredRadio(filtered);
  }, [filterData]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const searchTerm = e.target.value;
      setFilterData(searchTerm);
    }
  };
  const handleRadioSelect = (radio) => {
    setSelectedRadio(radio);
  };
  return (
    <div className="flex flex-col justify-center ">
      <Navbar
        value={filterData}
        onChange={(e) => setFilterData(e.target.value)}
        handleKeyPress={handleKeyPress}
      />
      <main className="h-full mt-24 w-full mb-5 px-1 md:px-5 flex flex-col ">
        <div className="my-3">
          <RadioPlayer
            src={selectedRadio.url}
            img={selectedRadio.img ? selectedRadio.img : "/noimage.jpeg"}
            title={selectedRadio.title}
          />
        </div>
        <div className="grid grid-cols-2  mt-4 mb-3 px-0 md:px-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 h-[60vh] w-full  overflow-y-scroll">
          {filteredRadio?.map((item, index) => {
            return (
              <div key={index}>
                <RadioCard
                  title={item.title}
                  img={item.img}
                  onSelect={() => handleRadioSelect(item)}
                />
              </div>
            );
          })}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Radio;
