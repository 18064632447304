const radioData = [
  {
    id: 3,
    title: "Antena 91",
    img: "/tv/antena_91.png",
    tag: "radio online",
    url: "https://emisora.zonasinergia.com/8176/stream",
    url2: "",
  },
  {
    id: 4,
    title: "Beats Radio",
    img: "/tv/beats.png",
    tag: "radio online",
    url: "https://streaming01.shockmedia.com.ar/9778/stream",
    url2: "",
  },
  /*PROBAR COMPONENTE*/
  {
    id: 5,
    title: "Cadena 3",
    img: "/tv/cadena3.jpg",
    tag: "radio online",
    url: "https://21933.live.streamtheworld.com/RADIO3.mp3",
    url2: "",
  },

  {
    id: 9,
    title: "Canal 90.7 FM",
    img: "/tv/fm_907.png",
    tag: "radio online",
    url: "https://streaming01.shockmedia.com.ar:10714/;stream.mp3",
    url2: "",
  },

  {
    id: 11.7,
    title: "La Popu",
    img: "/tv/lapopufm.png",
    tag: "radio online",
    url: "https://27363.live.streamtheworld.com/LAPOPU.mp3?dist=c3-web",
    url2: "https://liveradio.mediainbox.net/popular.mp3",
  },

  {
    id: 12.4,
    title: "Mas FM",
    img: "/tv/masfm.png",
    tag: "radio online",
    url: "https://01.solumedia.com.ar:8596/stream",
    url2: "",
  },

  {
    id: 12.8,
    title: "Power Max Radio",
    img: "/tv/radiopowermax.jpg",
    tag: "radio online",
    url: "https://streaming01.shockmedia.com.ar:10584/stream?1711616780940",
    url2: "",
  },
  {
    id: 12.9,
    title: "Radio Mitre",
    img: "/tv/radiomitre.png",
    tag: "radio online",
    url: "https://buecrplb01.cienradios.com.ar/Mitre790.aac ",
    url2: "",
  },

  {
    id: 13.5,
    title: "Blue Fm",
    img: "/tv/blue.jpeg",
    tag: "radio online",
    url: "https://26573.live.streamtheworld.com/BLUE_FM_100_7_SC",
    url2: "",
  },
  {
    id: 13.6,
    title: "Radio Disney",
    img: "/tv/radiodisney.jpeg",
    tag: "radio online",
    url: "https://27613.live.streamtheworld.com/DISNEY_ARG_BA_SC",
    url2: "https://27613.live.streamtheworld.com/DISNEY_ARG_BA_SC",
  },
  {
    id: 13.8,
    title: "La 100",
    img: "/tv/lacien.jpeg",
    tag: "radio online",
    url: "https://buecrplb01.cienradios.com.ar/la100.aac",
    url2: "",
  },
  {
    id: 13.7,
    title: "Radio maria",
    img: "/tv/radiomaria.jpeg",
    tag: "radio online",
    url: "https://cdn.instream.audio/:9141/stream",
    url2: "",
  },

  {
    id: 14,
    title: "Radio Delta",
    img: "/tv/delta.png",
    tag: "radio online",
    url: "https://cdn.instream.audio/:9069/stream",
    url2: "",
  },
  {
    id: 14.2,
    title: "Radio Continental",
    img: "/tv/continental.png",
    tag: "radio online",
    url: "https://edge03.radiohdvivo.com/stream/continental",
    url2: "",
  },
  {
    id: 14.3,
    title: "Radio Nacional",
    img: "https://www.radionacional.com.ar/reproductor/images/Logo_AM870_b.png",
    tag: "radio online",
    url: "https://sa.mp3.icecast.magma.edge-access.net/sc_rad1",
    url2: "",
  },
  {
    id: 15,
    title: "Radio Gol",
    img: "/tv/gol.jpeg",
    tag: "radio online",
    url: "https://radios.solumedia.com/6018/stream",
  },
];
module.exports = radioData;
