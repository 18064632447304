export const sidebarData = [
  { id: 1, title: "Todos" },
  /*  { id: 6, title: "Anime" }, */
  { id: 2, title: "Deportes" },
  { id: 4, title: "Entretenimiento" },
  { id: 7, title: "Infantiles" },
  { id: 7.1, title: "Internacional" },
  /*   { id: 8, title: "Moda" }, */
  { id: 3, title: "Noticias" },
  { id: 5, title: "Rural" },
  /* { id: 6, title: "Radio Online" }, */
];
