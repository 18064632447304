import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Cast } from "@jdion/cast";
import { CastProvider } from "@jdion/cast-react";
import TvPlayer from "./tvplayer";
import Game from "./game";
import Radio from "./radio";

const cast = new Cast();

const AppWrapper = () => {
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault(); // Deshabilitar el menú contextual
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <CastProvider instance={cast}>
      <Router>
        <Routes>
          <Route element={<App />} path="/" exact />
          <Route element={<TvPlayer />} path="/tvplayer" />
          <Route element={<Game />} path="/game" />
          <Route element={<Radio />} path="/radio" />
        </Routes>
      </Router>
    </CastProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);
