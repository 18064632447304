import React, { useEffect, useState } from "react";
import GameCard from "../src/components/games/gameCard";
import Navbar from "../src/components/menu/navbar";
import gameData from "../src/constants/gamesData";
import Footer from "../src/components/menu/footer";

const Game = () => {
  const [filterData, setFilterData] = useState("");
  const [filteredGames, setFilteredGames] = useState(gameData);

  useEffect(() => {
    const filtered = gameData.filter((item) =>
      item?.title?.toLowerCase()?.includes(filterData?.toLowerCase())
    );
    setFilteredGames(filtered);
  }, [filterData]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const searchTerm = e.target.value;
      setFilterData(searchTerm);
    }
  };

  return (
    <div className="flex flex-col justify-center ">
      <Navbar
        value={filterData}
        onChange={(e) => setFilterData(e.target.value)}
        handleKeyPress={handleKeyPress}
      />
      <main className="h-full w-full flex ">
        <div className="grid grid-cols-1   mt-24 mb-5 md:grid-cols-2  gap-4 h-full w-full mx-auto md:w-3/4 px-5">
          {filteredGames?.map((item) => {
            return (
              <GameCard
                id={item.id}
                img={item.img}
                url={item.url}
                title={item.title}
                description={item.description}
              />
            );
          })}
          <div className="text-3xl text-white text-center md:col-span-2 mt-3 bg-[#009FE3] border-2 border-white rounded-md flex items-center justify-between p-3">
            <img
              src="/logo-mobbyt.png"
              className="object-contain w-40"
              alt="Mobbyt"
            />
            <span className="w-full">
              Juegos creados con{" "}
              <a className="underline" href="https://mobbyt.com">
                Mobbyt.
              </a>
              <br />
              Crea tu usuario y empieza a crear tus propios juegos{" "}
              <a className="underline" href="https://mobbyt.com/makegame/">
                ¡aquí!
              </a>
            </span>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Game;
