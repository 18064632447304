import React, { useEffect, useState } from "react";
import Navbar from "../src/components/menu/navbar";
import channelData from "../src/constants/channelData";
import TvCard from "../src/components/tvPlayer/tvCard";
import Sidebar from "../src/components/menu/sidebar";
import Footer from "../src/components/menu/footer";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const [filterData, setFilterData] = useState("");
  const [filteredChannels, setFilteredChannels] = useState(channelData);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const filtered = channelData.filter(
      (item) =>
        item?.title?.toLowerCase()?.includes(filterData?.toLowerCase()) ||
        item?.tag?.toLowerCase()?.includes(filterData?.toLowerCase())
    );
    setFilteredChannels(filtered);
  }, [filterData]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const searchTerm = e.target.value;
      setFilterData(searchTerm);
    }
  };

  const handleFilterChange = (filterData) => {
    if (filterData === "" || filterData.toLowerCase() === "todos") {
      setFilteredChannels(channelData);
    } else if (filterData.toLowerCase() === "juegos educativos") {
      navigate("/game");
    } else {
      const filtered = channelData.filter(
        (item) =>
          item?.title?.toLowerCase().includes(filterData.toLowerCase()) ||
          item?.tag?.toLowerCase().includes(filterData.toLowerCase())
      );
      setFilteredChannels(filtered);
    }

    if (
      location.pathname === "/game" &&
      filterData.toLowerCase() === "juegos educativos"
    ) {
      navigate("/");
    }
  };

  return (
    <div className="flex flex-col justify-center ">
      <Navbar
        value={filterData}
        onChange={(e) => setFilterData(e.target.value)}
        handleKeyPress={handleKeyPress}
      />
      <main className="h-full w-full flex top-20 ">
        <Sidebar onFilterChange={handleFilterChange} />

        <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 sm:mx-auto lg:mx-0 mt-28 mb-5  md:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-8 lg:gap-6 h-full lg:h-[79vh] w-full md:ml-2 lg:ml-[220px] lg:w-4/5 md:px-1 px-5 overflow-x-hidden overflow-y-scroll">
          {filteredChannels?.map((item) => {
            return (
              <TvCard
                key={item.id}
                id={item.id}
                img={item.img}
                url={`/tvplayer?id=${item.id}`}
              />
            );
          })}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
