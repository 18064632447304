const gameData = [
  {
    id: 1,
    title: "Mentes Brillantes",
    description: "Juego de  trivia  orientado a niños de 10 años ",
    img: "/game/mentesbrillantes.jpg",
    tag: "juegos educativos",
    url: "https://mobbyt.com/videojuego/educativo/?Id=343565",
  },
  {
    id: 2,
    title: "Elementos naturales",
    description: "Duchazo sobre elementos de la naturaleza...",
    img: "/game/duchazo.jpg",
    tag: "juegos educativos",
    url: "https://mobbyt.com/videojuego/educativo/?Id=343561",
  },
  {
    id: 3,
    title: "Juego de la memoria",
    description: "Juego de la memoria Orientado a niños de 7 años en adelante.",
    img: "/game/memoria.jpg",
    tag: "juegos educativos",
    url: "https://mobbyt.com/videojuego/educativo/?Id=343567",
  },
  {
    id: 4,
    title: "Juego de matemática",
    description: "Entrenar la mente con el juego de la oca...",
    img: "/game/mathgame.png",
    tag: "juegos educativos",
    url: "https://mobbyt.com/videojuego/educativo/?Id=343567",
  },
  {
    id: 5,
    title: "Correspondencia de geometría",
    description: "Correspondencia geométrica para entrenar la mente!",
    img: "/game/geometria.png",
    tag: "juegos educativos",
    url: "https://mobbyt.com/videojuego/educativo/?Id=343569",
  },
  {
    id: 6,
    title: "Mentes Brillantes 2",
    description: "Juego de  trivia  orientado a niños de 8 años en adelante",
    img: "/game/mentes.jpg",
    tag: "juegos educativos",
    url: "https://mobbyt.com/videojuego/educativo/?Id=343571",
  },
  
];
module.exports = gameData;
