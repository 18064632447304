const channelData = [
  {
    id: 1,
    title: "24/7 Canal de Noticias en vivo",
    img: "/tv/247.png",
    tag: "noticias",
    url: "https://panel.host-live.com:19360/cn247tv/cn247tv.m3u8",
    url2: "",
  },
  {
    id: 2,
    title: "A 24 en vivo",
    img: "/tv/a24.png",
    tag: "noticias",
    url: "https://www.youtube.com/live/QGpHLgRnrx4",
    url2: "",
  },

  {
    id: 7,
    title: "Canal 26 en vivo",
    img: "/tv/canal_26.webp",
    tag: "noticias",
    url: "https://www.youtube.com/watch?v=MS0QdZ5zvn8",
    url2: "",
  },
  {
    id: 8,
    title: "Canal Rural en vivo",
    img: "/tv/canal_rural.png",
    tag: "rural, campo",
    // url2: "https://cda2.alsolnet.com/elrural/live/playlist.m3u8",
    url: "https://www.youtube.com/watch?v=0eA-D1S7jRE",
  },

  {
    id: 11.3,
    title: "El doce en vivo",
    img: "/tv/el_doce.png",
    tag: "noticias, entretenimiento",
    url: "https://www.youtube.com/watch?v=sFZe_RPnNSo&ab_channel=eldoce",
    url2: "",
  },
  {
    id: 11.4,
    title: "El trece en vivo",
    img: "/tv/el_trece.png",
    tag: "noticias, entretenimiento",
    url: "https://live-01-02-eltrece.vodgc.net/eltrecetv/index.m3u8",
    url2: "",
  },

  {
    id: 11.6,
    title: "El Garage TV",
    img: "/tv/el_garage.png",
    tag: "deportes, autos",
    url: "https://stream1.sersat.com/hls/garagetv.m3u8",
    url2: "",
  },

  {
    id: 11.8,
    title: "TN en vivo",
    img: "/tv/tn.png",
    tag: "noticias",
    url2: "https://live-01-01-tn.vodgc.net/TN24/index.m3u8",
    url: "https://www.youtube.com/watch?v=cb12KmMMDJA",
  },

  {
    id: 12,
    title: "C5N en vivo",
    img: "/tv/c5n.png",
    tag: "noticias, entretenimiento",
    url: "https://www.youtube.com/watch?v=NdQSOItOQ5Y",
    url2: "",
  },
  {
    id: 12.1,
    title: "La Nación + en vivo",
    img: "/tv/ln+.jpeg",
    tag: "noticias, entretenimiento",
    url: "https://www.youtube.com/watch?v=G5pHuBCqgrs",
    url2: "",
  },

  {
    id: 13.5,
    title: "Crónica TV en vivo",
    img: "/tv/cronica.jpg",
    tag: "noticias",
    url: "https://www.youtube.com/watch?v=avly0uwZzOE&feature=youtu.be",
    url2: "",
  },
  {
    id: 13.6,
    title: "El tres TV en vivo",
    img: "/tv/eltrestv.jpg",
    tag: "noticias",
    url: "https://www.youtube.com/watch?v=_Q_iTl1TtFs&ab_channel=eltresTV",
    url2: "",
  },
  {
    id: 17.8,
    title: "Telefe Noticias en vivo",
    img: "https://yt3.googleusercontent.com/OttUuKTaZLo0tUG6D9uG2_ZRIt7EaVN7_tVQIO4R3NHwb3QZdyBxK01CTn5JsOhVVdiAdUH2_nY=s160-c-k-c0x00ffffff-no-rj",
    tag: "noticias",
    url: "https://www.youtube.com/watch?v=8gs-9lsfVQU",
    url2: "",
  },

  {
    id: 15.4,
    title: "Canal 9 en vivo",
    img: "/tv/canal9.jpeg",
    tag: "entretenimiento, noticias",
    url: "https://www.twitch.tv/elnueve_ok",
    url2: "",
  },
  {
    id: 15.8,
    title: "France 24 en vivo",
    img: "/tv/france.png",
    tag: "noticias, internacional",
    url: "https://www.youtube.com/embed/Y-IlMeCCtIg?hl=es&showinfo=0&color=white&rel=1&enablejsapi=1&origin=https%3A%2F%2Fwww.france24.com&autoplay=1&controls=1&modestbranding=0&disablekb=0&mute=0&playsinline=0&embed_config=%7B%22primaryThemeColor%22%3A%22%2300a7e3%22%2C%22relatedChannels%22%3A%5B%22UCUdOoVWuWmgo1wByzcsyKDQ%22%5D%2C%22autonavRelatedVideos%22%3Atrue%2C%22hideInfoBar%22%3Atrue%2C%22adsConfig%22%3A%7B%22disableAds%22%3Atrue%7D%2C%22enableIma%22%3Atrue%7D&widgetid=1",
    url2: "",
  },

  {
    id: 16.2,
    title: "Euronews en vivo",
    img: "/tv/euro.jpg",
    tag: "noticias, internacional",
    url: "https://www.youtube.com/watch?v=O9mOtdZ-nSk",
    url2: "",
  },

  {
    id: 16.3,
    title: "QVC Italia en vivo",
    img: "/tv/qvc.png",
    tag: "noticias, internacional, entretenimiento",
    url: "https://www.youtube.com/watch?v=ZbcWG9iQ8-0",
    url2: "",
  },
  {
    id: 16.4,
    title: "Estación Espacial Internacional en vivo",
    img: "/tv/nasa.jpeg",
    tag: "noticias, internacional",
    url: "https://www.youtube.com/watch?v=lx2CNP7kwhI",
    url2: "",
  },
  {
    id: 16.5,
    title: "Terramia TV",
    img: "/tv/terramia.jpeg",
    tag: "entretenimiento",
    url: "https://stream-gtlc.telecentro.net.ar/hls/tierramiahls/0/playlist.m3u8",
    url2: "",
  },
  {
    id: 16.6,
    title: "Latina TV",
    img: "/tv/latinatv.png",
    tag: "entretenimiento",
    url: "https://stream-gtlc.telecentro.net.ar/hls/latinatvhls/0/playlist.m3u8",
    url2: "",
  },
  {
    id: 13.3,
    title: "Cosmos TV",
    img: "/tv/COSMOS-TV.webp",
    tag: "entretenimiento, noticias",
    url: "https://tv.mediacp.eu:19360/cosmos/cosmos.m3u8",
    url2: "",
  },

  {
    id: 12.3,
    title: "Magic Kids",
    img: "/tv/magickid.png",
    tag: "infantiles, entretenimiento",
    url: "https://magicstream.ddns.net/magicstream/stream.m3u8",
    url2: "",
  },

  {
    id: 16.7,
    title: "Un jefe en pañales",
    img: "/movie/jefe.jpg",
    tag: "infantiles, entretenimiento",
    url: "http://ia902704.us.archive.org/24/items/Peliculas-wii/Un%20Jefe%20en%20Pa%C3%B1ales.mp4",
    url2: "https://firebasestorage.googleapis.com/v0/b/netmaxplay-b61d9.appspot.com/o/movie%2FUn%20Jefe%20en%20Pa%C3%B1ales1.mp4?alt=media&token=4cc96dfb-1aa9-4ce1-b240-6364ba682f58",
  },
  {
    id: 16.8,
    title: "Un jefe en pañales 2",
    img: "/movie/Un_Jefe_en_Pañales_2_Poster.jpg",
    tag: "infantiles, entretenimiento",
    url: "http://ia902704.us.archive.org/24/items/Peliculas-wii/Un%20Jefe%20en%20Pa%C3%B1ales%202%20-%20Negocios%20de%20Familia.mp4",
    url2: "https://firebasestorage.googleapis.com/v0/b/netmaxplay-b61d9.appspot.com/o/movie%2FUn%20Jefe%20en%20Pa%C3%B1ales%202%20-%20Negocios%20de%20Familia.mp4?alt=media&token=dad719d6-9a0d-450f-92cc-59aa01f5902f",
  },
  {
    id: 16.9,
    title: "Mario Bros",
    img: "/movie/The_Super_Mario_Bros_Movie_logo.png",
    tag: "infantiles, entretenimiento",
    url: "http://ia902704.us.archive.org/24/items/X3N7N29N/SMABSP23.mkv",
    url2: "",
  },
];
module.exports = channelData;
