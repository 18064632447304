import React from "react";
import { Link } from "react-router-dom";

const TvCard = ({ img, url, id }) => {
  return (
    <div className="h-[150px] w-full md:h-[250px] md:w-[250px] z-0">
      <Link to={url}>
        <img
          className="bg-white opacity-90 rounded-md w-full md:w-[295px] relative h-[150px] md:h-[230px] border border-white shadow-sm shadow-white"
          src={img}
          alt={`Channel ${id}`}
          fill
        />
      </Link>
    </div>
  );
};

export default TvCard;
